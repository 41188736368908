<template>
  <v-container
    fill-height
    fluid
    class="login-page d-flex justify-content-center item-align-center"
  >
    <v-card width="400px" elevation="7">
      <v-app-bar color="primary" class="text-white pa-1">
        <v-toolbar-title class="mx-auto"
          ><strong> <h1>S.T.A.R.S.</h1></strong></v-toolbar-title
        >
      </v-app-bar>
      <v-card-text>
        <v-form lazy-validation ref="login" v-model="valid">
          <v-text-field
            v-model="email"
            placeholder="E-posta"
            required
            @keypress.enter="onSubmit"
            outlined
            prepend-icon="mdi-account"
            type="email"
          ></v-text-field>
          <v-text-field
            v-model="password"
            placeholder="Parola"
            required
            outlined
            @keypress.enter="onSubmit"
            prepend-icon="mdi-lock"
            :type="eye ? 'text' : 'password'"
            :append-icon="password?.length > 0 ? 'mdi-eye' : ''"
            @click:append="eye = !eye"
          ></v-text-field>
          <v-card-actions class="d-flex justify-content-end mt-2">
            <router-link :to="{ name: 'forgot-password' }">
              <v-btn color="secondary" class="text-dark mr-2"
                >Parolamı Unuttum</v-btn
              >
            </router-link>
            <v-btn
              color="primary"
              @click="onSubmit"
              @keypress.enter="onSubmit"
              :loading="loading"
              :disabled="!valid"
              >Giriş Yap</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { LOGIN, DELETE_STATE } from "@/core/services/store/auth.module";

export default {
  name: "SM-Login",
  data() {
    return {
      email: null,
      password: null,
      eye: false,
      valid: true,
      loader: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions([LOGIN, DELETE_STATE, "redirectAfterLogin"]),
    resetForm() {
      this.email = null;
      this.password = null;
    },
    async onSubmit() {
      this.loader = "loading";
      const valid = this.$refs.login.validate();
      if (!valid) {
        return;
      }
      await this.deleteState();
      try {
        await this.login({ email: this.email, password: this.password });
        this.redirectAfterLogin();
      } catch {
        await this.deleteState();
        this.$toast.error("E-Posta ve ya parola alanı hatalı.", {
          position: "bottom-right",
          duration: 2000,
        });
      }
      this[this.loader] = false;
      this.loader = null;
    },
  },
  watch: {
    password() {
      if (this.eye) {
        this.eye = false;
      }
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];
    },
  },
};
</script>
